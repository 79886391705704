<template>
  <div>
    <b-modal
      ref="modalFiltros"
      v-model="showModal"
      title="Escolha seus filtros"
      cancel-title="cancelar"
      cancel-variant="danger"
      ok-title="Filtrar"
      @hidden="onModalHidden"
      @ok="filtrar"
    >
      <label>Data abertura</label>
      <b-row>
        <b-col cols="6">
          <b-form-input
            id="abertura-inicio"
            v-model="abertura.inicio"
            locale="pt"
            type="date"
          />
        </b-col>
        <b-col cols="6">
          <b-form-input
            id="abertura-final"
            v-model="abertura.final"
            locale="pt"
            type="date"
          />
        </b-col>
      </b-row>
      <label v-if="empresaID"> Seguradora</label>
      <v-select
        v-if="empresaID"
        v-model="seguradora.selected"
        :options="seguradora.options"
        multiple
      />
      <label>Revenda</label>
      <v-select
        v-model="revenda.selected"
        :options="revenda.options"
        multiple
      />
      <label>Marca</label>
      <v-select
        v-model="marca.selected"
        :options="marca.options"
        multiple
      />
      <label>Linha</label>
      <v-select
        v-model="linha.selected"
        :options="linha.options"
        multiple
      />
      <label>Tipo Atendimento</label>
      <v-select
        v-model="tipoAtendimento.selected"
        :options="tipoAtendimento.options"
        multiple
      />
      <label>Status Atendimento</label>
      <v-select
        v-model="statusAtendimento.selected"
        :options="statusAtendimento.options"
        multiple
      />
      <label v-if="empresaID">Atendente</label>
      <v-select
        v-if="empresaID"
        v-model="atendente.selected"
        :options="atendente.options"
        multiple
      />
      <label>Região</label>
      <v-select
        v-model="regiao.selected"
        :options="regiao.options"
        multiple
      />
      <label>Estado</label>
      <v-select
        v-model="estado.selected"
        :options="estado.options"
        multiple
        @input="getCidadesPorEstado"
      />
      <label>Cidade</label>
      <v-select
        v-model="cidade.selected"
        :options="cidade.options"
        multiple
      />
    </b-modal>
  </div>
</template>

<script>
import { BModal, BRow, BCol, BFormInput } from 'bootstrap-vue'
import vSelect from 'vue-select'
import axios from '../../../axios-auth'

export default {
  components: {
    BModal,
    vSelect,
    BRow,
    BCol,
    BFormInput,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      seguradora: {
        selected: [],
        options: [],
      },
      revenda: {
        selected: [],
        options: [],
      },
      marca: {
        selected: [],
        options: [],
      },
      linha: {
        selected: [],
        options: [],
      },
      tipoAtendimento: {
        selected: [],
        options: [
          'NORMAL',
          'PRIORITÁRIO',
          'EMERGENCIAL',
        ],
      },
      statusAtendimento: {
        selected: [],
        options: [],
      },
      atendente: {
        selected: [],
        options: [],
      },
      regiao: {
        selected: [],
        options: [],
      },
      estado: {
        selected: [],
        options: [],
      },
      cidade: {
        selected: [],
        options: [],
      },
      abertura: {
        inicio: '',
        final: '',
      },
      responseFiltro: [],
    }
  },
  created() {
    this.empresaID = JSON.parse(localStorage.getItem('userData'))?.empresa_id
    this.getSeguradoras()
    this.getRevendas()
    this.getMarcas()
    this.getLinhas()
    this.getAtendentes()
    this.getStatusOs()
    this.getRegiao()
    this.getEstado()
  },
  methods: {
    onModalHidden() {
      this.$emit('modal-hidden')
      // this.clearFiltro()
    },
    clearFiltro() {
      this.seguradora.selected = []
      this.revenda.selected = []
      this.marca.selected = []
      this.linha.selected = []
      this.tipoAtendimento.selected = []
      this.statusAtendimento.selected = []
      this.atendente.selected = []
      this.regiao.selected = []
      this.estado.selected = []
      this.cidade.selected = []
      this.abertura.inicio = ''
      this.abertura.final = ''
    },
    getSeguradoras() {
      if (this.empresaID) {
        this.seguradora.options = []
        axios
          .get(`api/empresas/seguradoras/${this.empresaID}`)
          .then(res => {
            res.data.dados.map(item => {
              this.seguradora.options.push({
                label: item.nome,
                id: item.id,
              })
            })
          })
      }
    },
    getRevendas() {
      this.revenda.options = []
      axios
        .get(this.empresaID ? `api/empresas/revendas/${this.empresaID}` : 'api/revenda')
        .then(res => {
          res.data.dados.map(item => {
            this.revenda.options.push({
              label: item.nome,
              id: item.id,
            })
          })
        })
    },
    getMarcas() {
      this.marca.options = []
      axios
        .get(this.empresaID ? `api/empresas/marcas/${this.empresaID}` : 'api/marca')
        .then(res => {
          res.data.dados.map(item => {
            this.marca.options.push({
              label: item.nome,
              id: item.id,
            })
          })
        })
    },
    getLinhas() {
      this.linha.options = []
      axios
        .get(this.empresaID ? `api/empresas/linhas/${this.empresaID}` : 'api/linha')
        .then(res => {
          res.data.dados.map(item => {
            this.linha.options.push({
              label: item.nome,
              id: item.id,
            })
          })
        })
    },
    getAtendentes() {
      if (this.empresaID) {
        this.atendente.options = []
        axios
          .get(`api/empresas/atendentes/${this.empresaID}`)
          .then(res => {
            res.data.dados.map(item => {
              this.atendente.options.push({
                label: item.name,
                id: item.id,
              })
            })
          })
      }
    },
    getStatusOs() {
      this.statusAtendimento.options = []
      axios
        .get('api/ordem_servico/listar/status_os')
        .then(res => {
          res.data.dados.map(item => {
            this.statusAtendimento.options.push({
              label: item.nome,
              id: item.id,
            })
          })
        })
    },
    getRegiao() {
      this.regiao.options = []
      axios
        .get('api/localidade/regiao')
        .then(res => {
          res.data.dados.map(item => {
            this.regiao.options.push({
              label: item.nome,
              id: item.id,
            })
          })
        })
    },
    getEstado() {
      this.estado.options = []
      axios
        .get('api/localidade/estado')
        .then(res => {
          res.data.dados.map(item => {
            this.estado.options.push({
              label: item.nome,
              id: item.id,
            })
          })
        })
    },
    getCidadesPorEstado() {
      const estadoId = this.estado.selected.map(e => e.id)
      this.getCidade(estadoId)
    },
    getCidade(id) {
      this.cidade.options = []
      axios
        .get(`api/localidade/cidade/${id}`)
        .then(res => {
          res.data.dados.map(item => {
            this.cidade.options.push({
              label: item.nome,
              id: item.id,
            })
          })
        })
    },
    filtrar() {
      this.responseFiltro = []
      let body = {}
      body = {
        empresa_id: this.empresaID,
        filtros: {},
      }
      if (this.abertura.inicio && this.abertura.final) {
        body.filtros.abertura = {
          data_inicial: `${this.abertura.inicio} 00:00:00`,
          data_final: `${this.abertura.final} 23:59:59`,
        }
      }
      if (this.seguradora.selected.length) {
        const seguradores = this.seguradora.selected.map(item => item.id)
        body.filtros.seguradora = seguradores
      }
      if (this.revenda.selected.length) {
        const revendas = this.revenda.selected.map(item => item.id)
        body.filtros.revenda = revendas
      }
      if (this.linha.selected.length) {
        const linhas = this.linha.selected.map(item => item.id)
        body.filtros.linha = linhas
      }
      if (this.marca.selected.length) {
        const marcas = this.marca.selected.map(item => item.id)
        body.filtros.marca = marcas
      }
      if (this.tipoAtendimento.selected.length) {
        body.filtros.tipoAtendimento = this.tipoAtendimento.selected
      }
      if (this.statusAtendimento.selected.length) {
        const atendimentos = this.statusAtendimento.selected.map(item => item.id)
        body.filtros.statusAtendimento = atendimentos
      }

      if (this.atendente.selected.length) {
        const atendentes = this.atendente.selected.map(item => item.id)
        body.filtros.atendente = atendentes
      }
      if (this.regiao.selected.length) {
        const regioes = this.regiao.selected.map(item => item.id)
        body.filtros.regiao = regioes
      }

      if (this.estado.selected.length) {
        const estado = this.estado.selected.map(item => item.id)
        body.filtros.estado = estado
      }
      if (this.cidade.selected.length) {
        const cidade = this.cidade.selected.map(item => item.id)
        body.filtros.cidade = cidade
      }
      axios
        .post('api/ordem_servico/filtrar', body)
        .then(res => {
          res.data.dados.map(item => {
            this.responseFiltro.push(item)
          })
          this.clearFiltro()
          this.$emit('responsefiltro', this.responseFiltro)
        })
        .catch(error => {
          this.clearFiltro()
          if (error.response) {
            this.$swal({
              title: 'Erro!',
              text: error.response.data.mensagem,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
        })
    },
  },
}
</script>
