<template>
  <div>
    <b-row class="match-height">
      <b-col md="4">
        <Analytic
          :items="filteredList"
          card-text="Orçamentos"
          :not-done-status="['AUDITADO']"
          :done-status="['ENVIADO PARA SEGURADORA']"
          :period-filter-set="periodFilter"
        />
      </b-col>
      <b-col
        md="8"
      >
        <statistics-direcionador :data="statisticsItems" />
      </b-col>
      <b-col
        md="12"
      >
        <totais-orcamento-status :set-status-filter="setStatusFilter" />
      </b-col>
    </b-row>
    <section>
      <card-list-orcamento
        ref="listOrcamento"
        :update-grid="getListOrcamentos"
        :loading="loading"
      />
    </section>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { mapState, mapGetters } from 'vuex'
import Analytic from '@/components/orcamento/Analytic.vue'
import statisticsDirecionador from '../../dashboard/cards/CardStatistics.vue'
import CardListOrcamento from './cards/CarListOrcamento.vue'
import TotaisOrcamentoStatus from './cards/TotaisOrcamentoStatus.vue'

export default {
  components: {
    BRow,
    BCol,
    statisticsDirecionador,
    CardListOrcamento,
    TotaisOrcamentoStatus,
    Analytic,
  },
  data() {
    return {
      statisticsItems: [
        {
          icon: 'DollarSignIcon',
          color: 'light-success',
          title: '',
          subtitle: 'CMR',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'DollarSignIcon',
          color: 'light-warning',
          title: '',
          subtitle: 'CMG',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'CalendarIcon',
          color: 'light-primary',
          title: ' Dias',
          subtitle: 'TAT',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'RefreshCcwIcon',
          color: 'light-info',
          title: '%',
          subtitle: 'PT',
          customClass: '',
        },
        {
          icon: 'AlertTriangleIcon',
          color: 'light-warning',
          title: '%',
          subtitle: 'REP',
          customClass: '',
        },
        {
          icon: 'RepeatIcon',
          color: 'light-info',
          title: '%',
          subtitle: 'REIN',
          customClass: '',
        },
        {
          icon: 'AlertTriangleIcon',
          color: 'light-danger',
          title: '%',
          subtitle: 'REC',
          customClass: '',
        },
        {
          icon: 'CornerUpLeftIcon',
          color: 'light-info',
          title: '0 %',
          subtitle: 'LMI',
          customClass: '',
        },
      ],
      loading: false,
    }
  },
  computed: {
    ...mapState({
      filteredList: state => state.orcamento.filteredList,
    }),
    ...mapGetters({
      indicators: 'indicatorsData',
    }),
  },
  watch: {
    indicators(n) {
      this.statisticsItems[0].title = `R$ ${n.cmr}`
      this.statisticsItems[1].title = `R$ ${n.cmg}`
      this.statisticsItems[2].title = `${n.tat} Dias`
      this.statisticsItems[3].title = `${n.pt}%`
      this.statisticsItems[4].title = `${n.rep}%`
      this.statisticsItems[5].title = `${n.rein}%`
      this.statisticsItems[6].title = `${n.rec}%`
      this.statisticsItems[7].title = `${n.lmi}%`
    },
  },
  created() {
    this.getListOrcamentos()
  },
  methods: {
    getListOrcamentos(query) {
      if (!query) {
        query = {
          data_final: '2024-12-01',
          data_inicial: '2024-04-02',
        }
      }
      this.items = null
      this.$store.dispatch('getListOrcamentos', { modulo: 'orcamento', query })
        .then(res => {
          this.items = res.data.dados.filter(item => item.servicos.some(servico => servico.tipo_servico !== 'VISITA'))
        })
    },

    setStatusFilter(status) {
      this.$refs.listOrcamento.setStatusFilterByCard(status)
    },

    periodFilter(value) {
      if (value) {
        // const { endDate, startDate } = value
        this.getListOrcamentos(value)
        return null
      }
      this.getListOrcamentos()
      return null
    },
  },
}
</script>
