<template>
  <div>
    <!-- Stats Card Vertical -->
    <b-row class="match-height">
      <b-col
        xl="2"
        md="2"
        sm="6"
        @click="setSf('AGUARDANDO AUDITORIA')"
      >
        <statistic-card-vertical
          color="primary"
          icon="AlertCircleIcon"
          :statistic="totaisOrcamentoStatus.aguardando_auditoria"
          statistic-title="Aguardando auditoria"
          class="card-totais-atendimento-status"
        />
      </b-col>
      <b-col
        xl="2"
        md="2"
        sm="6"
        @click="setSf('AUDITADO')"
      >
        <statistic-card-vertical
          color="warning"
          icon="CalendarIcon"
          :statistic="totaisOrcamentoStatus.auditado"
          statistic-title="Auditado"
          class="card-totais-atendimento-status"
        />
      </b-col>
      <b-col
        xl="2"
        md="2"
        sm="6"
        @click="setSf('ENVIADO PARA SEGURADORA')"
      >
        <statistic-card-vertical
          color="secondary"
          icon="AlertOctagonIcon"
          :statistic="totaisOrcamentoStatus.enviado_para_seguradora"
          statistic-title="Enviado para seguradora"
          class="card-totais-atendimento-status"
        />
      </b-col>
      <b-col
        xl="2"
        md="2"
        sm="6"
        @click="setSf('APROVADO - AGUARDANDO PAGAMENTO')"
      >
        <statistic-card-vertical
          color="warning"
          icon="MessageSquareIcon"
          :statistic="totaisOrcamentoStatus.aprovado_aguardando_pagamento"
          statistic-title="Aprovado - Aguardando Pagamento"
          class="card-totais-atendimento-status"
        />
      </b-col>
      <b-col
        xl="2"
        md="2"
        sm="6"
        @click="setSf('APROVADO - PAGO')"
      >
        <statistic-card-vertical
          color="success"
          icon="DollarSignIcon"
          :statistic="totaisOrcamentoStatus.aprovado_pago"
          statistic-title="Aprovado - Pago"
          class="card-totais-atendimento-status"
        />
      </b-col>
      <b-col
        xl="2"
        md="2"
        sm="6"
        @click="setSf('NEGADO')"
      >
        <statistic-card-vertical
          color="warning"
          icon="MessageSquareIcon"
          :statistic="totaisOrcamentoStatus.negado"
          statistic-title="Negado"
          class="card-totais-atendimento-status"
        />
      </b-col>

    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import { kFormatter } from '@core/utils/filter'
import { mapState } from 'vuex'
import listDirecionadorOs from '@/views/direcionador/dashboard/components/ListDirecionadorOs.vue'
import axios from '../../../../../axios-auth'

export default {
  components: {
    BRow,
    BCol,
    StatisticCardVertical,
  },
  props: {
    setStatusFilter: {
      required: true,
      type: Function,
    },
  },
  data() {
    return {
      totalizadores: [],
    }
  },
  computed: {
    ...mapState({
      items: state => state.orcamento.filteredList,
    }),
    totaisOrcamentoStatus() {
      const { items } = this

      const totalStatus = {
        aguardando_auditoria: 0,
        auditado: 0,
        aprovado_aguardando_pagamento: 0,
        aprovado_pago: 0,
        enviado_para_seguradora: 0,
        negado: 0,
      }

      items.forEach(item => {
        switch (item.status) {
          case 'AGUARDANDO AUDITORIA': {
            totalStatus.aguardando_auditoria += 1
            break
          }

          case 'AUDITADO': {
            totalStatus.auditado += 1
            break
          }

          case 'ENVIADO PARA SEGURADORA': {
            totalStatus.enviado_para_seguradora += 1
            break
          }

          case 'APROVADO - AGUARDANDO PAGAMENTO': {
            totalStatus.aprovado_aguardando_pagamento += 1
            break
          }

          case 'APROVADO - PAGO': {
            totalStatus.aprovado_pago += 1
            break
          }

          case 'NEGADO': {
            totalStatus.negado += 1
            break
          }

          default:
            break
        }
      })

      return {
        ...totalStatus,
      }
    },
  },
  methods: {
    kFormatter,
    getTotalizadoresOs(id) {
      axios.get(`api/empresas/totalizadores/orcamento/${id}`)
        .then(res => {
          this.totalizadores = res.data.dados
        })
    },
    selectCard(status) {
      listDirecionadorOs.methods.incrementCounter()
      this.$ls.set('status', status)
    },
    cardColor(status) {
      const colors = {
        AGENDAR: 'warning',
        'EM ATENDIMENTO': 'primary',
        'AGUARDANDO APROVAÇÃO': 'dark',
        'EM REPARO': 'secondary',
        'REPARO CONCLUÍDO': 'success',
        'REPARO ENTREGUE': 'primary',
        TROCA: 'info',
        NEGADO: 'danger',
      }
      return colors[status] || 'primary'
    },
    cardIcon(status) {
      const icons = {
        AGENDAR: 'CalendarIcon',
        'EM ATENDIMENTO': 'MessageSquareIcon',
        'AGUARDANDO APROVAÇÃO': 'ShoppingBagIcon',
        'EM REPARO': 'ToolIcon',
        'REPARO CONCLUÍDO': 'AwardIcon',
        'REPARO ENTREGUE': 'TruckIcon',
        TROCA: 'RefreshCcwIcon',
        NEGADO: 'ThumbsDownIcon',
      }
      return icons[status] || 'QuestionMarkIcon'
    },

    setSf(status) {
      this.setStatusFilter(status)
    },
  },
}
</script>
<style>
.card-totais-atendimento-status:hover {
  background-color: #5e9fe7;
  cursor: pointer;
}
</style>
