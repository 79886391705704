<template>
  <b-card v-if="items">
    <b-row class="mb-2">
      <b-col
        md="2"
      >
        <label>Status</label>
        <v-select
          v-model="statusFilter"
          :options="optionsStatus"
          multiple
        />
      </b-col>
      <div class="col-md-2 mb-2">
        <label>Atendente</label>
        <v-select
          v-model="atendenteFilter"
          :options="optionsAtendenteFilter"
          multiple
        />
      </div>
      <b-col
        md="2"
      >
        <label>Tipo de Atendimento</label>
        <v-select
          v-model="tipoAtendimentoFilter"
          :options="optionsTipoAtendimento"
          multiple
        />
      </b-col>
      <b-col
        md="2"
      >
        <label>Dias em Aberto</label>
        <v-select
          multiple
        />
      </b-col>
      <b-col
        md="2"
      >
        <label>Seguradora</label>
        <v-select
          v-model="seguradoraFilter"
          :options="optionsSeguradora"
          multiple
        />
      </b-col>
      <b-col
        md="2"
      >
        <label>Marca</label>
        <v-select
          v-model="marcaFilter"
          :options="optionsMarca"
          multiple
        />
      </b-col>
    </b-row>
    <b-row>
      <b-modal
        id="modal-detalhe-orcamento"
        ref="showModalOrcamento"
        size="xl"
        :title="`Orcamento ${infoOrcamento.orcamento_id}`"
        @hidden="editavel=false, clearAcoes()"
      >
        <b-tabs>
          <b-tab v-if="visualizarDadosModal">
            <template #title>
              <feather-icon icon="FileTextIcon" />
              <span>Orcamento</span>
            </template>

            <b-row class="mb-1">
              <b-col md="2">
                <label><strong>Sinistro</strong></label>
                <b-form-input
                  :value="infoOrcamento.sinistro"
                  disabled
                />
              </b-col>
              <b-col md="2">
                <label for=""><strong>Data Abertura</strong></label>
                <b-form-input
                  :value="infoOrcamento.data_abertura ? formatTimezone(infoOrcamento.data_abertura, true) : ''"
                  disabled
                />
              </b-col>
              <b-col md="2">
                <label for=""><strong>Status</strong></label>
                <b-form-input
                  :value="infoOrcamento.status"
                  disabled
                />
              </b-col>
              <b-col md="2">
                <label for=""><strong>Valor Total Orcamento</strong></label>
                <money
                  :value="infoOrcamento.valor_total_orcamento ? valorBr(infoOrcamento.valor_total_orcamento, true) : ''"
                  v-bind="money"
                  class="form-control"
                  readonly
                />
              </b-col>
              <b-col md="2">
                <label for=""><strong>Valor Aprovado no Sistema</strong></label>
                <!-- <b-form-input
                  :value="infoOrcamento.valor_total_aprovado ? valorBr(infoOrcamento.valor_total_aprovado, true) : ''"
                  disabled
                /> -->
                <money
                  :value="infoOrcamento.valor_total_aprovado ? valorBr(infoOrcamento.valor_total_aprovado, true) : ''"
                  v-bind="money"
                  class="form-control"
                  readonly
                />
              </b-col>
              <b-col md="2">
                <label for=""><strong>Tipo Orçamento</strong></label>
                <b-form-input
                  :value="infoOrcamento.tipo_orcamento"
                  disabled
                />
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <label><strong>Data Criação</strong></label>
                <b-form-input
                  :value="infoOrcamento.data_criacao ? formatTimezone(infoOrcamento.data_criacao, true) : ''"
                  disabled
                />
              </b-col>
              <b-col>
                <label><strong>Data Auditação</strong></label>
                <b-form-input
                  :value="infoOrcamento.data_auditacao ? formatTimezone(infoOrcamento.data_auditacao, true) : ''"
                  disabled
                />
              </b-col>
              <b-col>
                <label><strong>Data Envio Orçamento</strong></label>
                <b-form-input
                  v-if="!editavel || infoOrcamento.status === 'ENVIADO PARA SEGURADORA'"
                  :value="infoOrcamento.data_envio_orcamento ? formatTimezone(infoOrcamento.data_envio_orcamento, true) : ''"
                  disabled
                />
                <flat-pickr
                  v-else-if="editavel"
                  v-model="dataEnvioOrcamento"
                  class="form-control"
                />
              </b-col>
              <b-col>
                <label><strong>Status Retorno</strong></label>
                <b-form-input
                  v-if="!editavel || infoOrcamento.status !== 'ENVIADO PARA SEGURADORA'"
                  :value="infoOrcamento.status_retorno"
                  disabled
                />
                <b-form-input
                  v-else-if="editavel || infoOrcamento.status === 'ENVIADO PARA SEGURADORA'"
                  v-model="statusRetorno"
                  placeholder="APROVADO OU NEGADO"
                />
              </b-col>
              <b-col>
                <label><strong>Valor Envio Seguradora</strong></label>
                <money
                  v-if="!editavel"
                  :value="infoOrcamento.valor_envio_seguradora"
                  v-bind="money"
                  class="form-control"
                  readonly
                />

                <money
                  v-else-if="editavel"
                  v-model="infoOrcamento.valor_envio_seguradora"
                  v-bind="money"
                  class="form-control"
                />
              </b-col>
              <b-col>
                <label><strong>Valor Total Seguradora</strong></label>
                <!-- <b-form-input
                  v-if="!editavel || infoOrcamento.status !== 'ENVIADO PARA SEGURADORA'"
                  :value="infoOrcamento.valor_total_seguradora"
                  disabled
                />
                <b-form-input
                  v-else-if="editavel || infoOrcamento.status === 'ENVIADO PARA SEGURADORA'"
                  v-model="valorTotalSeguradora"
                /> -->
                <money
                  v-if="!editavel"
                  :value="infoOrcamento.valor_total_seguradora"
                  v-bind="money"
                  class="form-control"
                  readonly
                />

                <money
                  v-else-if="editavel"
                  v-model="infoOrcamento.valor_total_seguradora"
                  v-bind="money"
                  class="form-control"
                />
              </b-col>
              <b-col>
                <label><strong>Modelo Produto</strong></label>
                <b-form-input
                  v-model="infoOrcamento.modelo"
                  :disabled="!editavel"
                />
              </b-col>
              <b-col>
                <label><strong>Nº de Série</strong></label>
                <b-form-input
                  v-model="infoOrcamento.numero_serie"
                  :disabled="!editavel"
                />
              </b-col>
              <b-col>
                <b-row>
                  <b-col>
                    <label><strong>Retorno Orçamento</strong></label>
                    <b-form-input
                      v-if="!editavel || infoOrcamento.status !== 'ENVIADO PARA SEGURADORA'"
                      :value="infoOrcamento.data_retorno_orcamento ? formatTimezone(infoOrcamento.data_retorno_orcamento, true) : ''"
                      disabled
                    />
                    <flat-pickr
                      v-else-if="editavel && infoOrcamento.status === 'ENVIADO PARA SEGURADORA'"
                      v-model="dataRetornoOrcamento"
                      class="form-control"
                    />
                  </b-col>
                  <feather-icon
                    v-if="infoOrcamento.status === 'AUDITADO' || infoOrcamento.status === 'ENVIADO PARA SEGURADORA'"
                    class="mt-2 cursor-pointer"
                    size="36"
                    icon="EditIcon"
                    @click="editavel = true"
                  />
                </b-row>
              </b-col>
            </b-row>
            <hr>
            <b-row
              v-for="(dados,index) in infoOrcamento.servicos"
              :key="index"
              class="mb-1"
            >
              <b-col md="2">
                <label for=""><strong>Tipo Servico</strong></label>
                <v-select
                  v-model="dados.tipo_servico"
                  :disabled="!editavel && dados.tipo_servico !== 'VISITA'"
                  :options="tiposServicosOptions"
                />
              </b-col>
              <b-col md="3">
                <label for=""><strong>Descrição</strong></label>
                <b-form-input
                  v-model="dados.descricao"
                  :disabled="!editavel"
                />
              </b-col>
              <b-col md="2">
                <label for=""><strong>Observação</strong></label>
                <b-row>
                  <b-col>
                    <b-form-input
                      v-model="dados.observacao"
                      :disabled="!editavel"
                    />
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="1">
                <label for=""><strong>Quant.</strong></label>
                <b-form-input
                  v-model="dados.quantidade"
                  :disabled="!editavel"
                />
              </b-col>
              <b-col md="2">
                <label for=""><strong>Valor Solicitado</strong></label>
                <div class="d-flex">
                  <!-- <b-form-input
                    v-model="dados.valor_solicitado"
                    :disabled="!editavel"
                  /> -->
                  <money
                    v-model="dados.valor_solicitado"
                    v-bind="money"
                    class="form-control"
                    :disabled="!editavel"
                  />
                </div>
              </b-col>
              <b-col md="2">
                <label for=""><strong>Valor Aprovado</strong></label>
                <b-row>
                  <b-col>
                    <!-- <b-form-input
                      v-model="dados.valor_aprovado"
                      :disabled="!editavel"
                    /> -->
                    <money
                      v-model="dados.valor_aprovado"
                      v-bind="money"
                      class="form-control"
                      :disabled="!editavel"
                    />
                  </b-col>
                </b-row>
              </b-col>
            </b-row>

            <b-row class="mb-1">
              <b-col md="">
                <label for=""><strong>Atendente</strong></label>
                <b-form-input
                  :value="infoOrcamento.atendente"
                  disabled
                />
              </b-col>
            </b-row>

          </b-tab>
          <b-tab v-if="visualizarDadosModal">
            <template #title>
              <feather-icon icon="FileTextIcon" />
              <span>Prestador de Serviço</span>
            </template>

            <b-row class="mb-1">
              <b-col md="2">
                <label><strong>NOME</strong></label>
                <b-form-input
                  :value="infoOrcamento.prestador_servico ? infoOrcamento.prestador_servico.nome : ''"
                  disabled
                />
              </b-col>
              <b-col md="2">
                <label for=""><strong>CPF_CNPJ</strong></label>
                <b-form-input
                  :value="infoOrcamento.prestador_servico ? infoOrcamento.prestador_servico.cpf_cnpj : ''"
                  disabled
                />
              </b-col>
              <b-col md="2">
                <label for=""><strong>CHAVE PIX</strong></label>
                <b-form-input
                  :value="infoOrcamento.prestador_servico ? infoOrcamento.prestador_servico.chave_pix : ''"
                  disabled
                />
              </b-col>
              <b-col md="2">
                <label for=""><strong>BANCO</strong></label>
                <b-form-input
                  :value="infoOrcamento.prestador_servico ? infoOrcamento.prestador_servico.banco : ''"
                  disabled
                />
              </b-col>
              <b-col md="2">
                <label for=""><strong>AGENCIA</strong></label>
                <b-form-input
                  :value="infoOrcamento.prestador_servico ? infoOrcamento.prestador_servico.agencia : ''"
                  disabled
                />
              </b-col>
              <b-col md="2">
                <label for=""><strong>CONTA</strong></label>
                <b-form-input
                  :value="infoOrcamento.prestador_servico ? infoOrcamento.prestador_servico.conta : ''"
                  disabled
                />
              </b-col>
              <b-col md="2">
                <label for=""><strong>TELEFONE</strong></label>
                <b-form-input
                  :value="infoOrcamento.prestador_servico ? infoOrcamento.prestador_servico.telefone : ''"
                  disabled
                />
              </b-col>
              <b-col md="2">
                <label for=""><strong>EMAIL</strong></label>
                <b-form-input
                  :value="infoOrcamento.prestador_servico ? infoOrcamento.prestador_servico.email : ''"
                  disabled
                />
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
        <template #modal-footer="{}">
          <b-button
            v-show="infoOrcamento.status === 'REVISÃO SEGURADORA'"
            variant="success"
            @click="revisaoSeguradora('APROVADO')"
          >
            Aprovar Revisão Seguradora
          </b-button>
          <b-button
            v-show="infoOrcamento.status === 'REVISÃO SEGURADORA'"
            variant="danger"
            @click="revisaoSeguradora('NEGADO')"
          >
            Negar Revisão Seguradora
          </b-button>
          <b-button
            v-show="infoOrcamento.status !== 'NEGADO' && !editavel"
            class="mr-1"
            size="md"
            variant="secondary"
            @click="editOrcamento"
          >
            Editar
          </b-button>

          <b-button
            v-if="editavel"
            class="mr-1"
            size="md"
            variant="success"
            @click="saveEditOrcamento"
          >
            Salvar
          </b-button>

          <b-button
            v-if="editavel"
            class="mr-1"
            size="md"
            variant="outline-danger"
            @click="cancelEditOrcamento"
          >
            Cancelar
          </b-button>

          <b-button
            v-show="infoOrcamento.status === 'AUDITADO'"
            class="mr-1"
            size="md"
            variant="outline-warning"
            @click="openModalRevisarOrcamento"
          >
            Revisão
          </b-button>
          <b-button
            v-if="infoOrcamento.status === 'AUDITADO' && infoOrcamento.hasOwnProperty('servicos') && infoOrcamento.servicos.length > 0 && infoOrcamento.servicos[0].tipo_servico === 'VISITA' || infoOrcamento.tipo_orcamento === 'AVULSO'"
            class="mr-1"
            size="md"
            variant="outline-warning"
            :disabled="dataEnvioOrcamento === ''"
            @click="enviarOrcamento"
          >
            CONFIRMAR ENVIO DO ORÇAMENTO
            <feather-icon
              class="plus-icon"
              size="16"
              icon="ThumbsUpIcon"
            />
          </b-button>
          <b-button
            v-if="infoOrcamento.status === 'AUDITADO' && infoOrcamento.hasOwnProperty('servicos') && infoOrcamento.servicos.length > 0 && infoOrcamento.servicos[0].tipo_servico !== 'VISITA'"
            class="mr-1"
            size="md"
            variant="outline-warning"
            :disabled="dataEnvioOrcamento === ''"
            @click="enviarOrcamento"
          >
            CONFIRMAR ENVIO PARA SEGURADORA
            <feather-icon
              class="plus-icon"
              size="16"
              icon="ThumbsUpIcon"
            />
          </b-button>
          <b-button
            v-show="infoOrcamento.status === 'ENVIADO PARA SEGURADORA'"
            class="mr-1"
            size="md"
            variant="outline-success"
            :disabled="dataRetornoOrcamento === ''
              || statusRetorno === ''
              || (infoOrcamento.valor_total_seguradora === null || infoOrcamento.valor_total_seguradora <= 0)"
            @click="retornoOrcamento"
          >
            CONFIRMAR RETORNO DA SEGURADORA
            <feather-icon
              class="color-icon"
              size="16"
              icon="ThumbsUpIcon"
            />
          </b-button>
          <b-button
            size="md"
            variant="outline-primary"
            @click="toViewOs(infoOrcamento.ordem_de_servico_id)"
          >
            Ir Para OS
            <feather-icon
              class="color-icon"
              size="16"
              icon="ExternalLinkIcon"
            />
          </b-button>
        </template>
      </b-modal>
      <b-modal
        ref="modalRealizarTroca"
        title="Realizar Troca"
        ok-title="Realizar Troca"
        cancel-title="Cancelar"
        cancel-variant="danger"
        :ok-disabled="descricaoTroca === ''"
        @hidden="clearAcaoTroca()"
        @ok="realizarTroca"
      >
        <div>
          <label><strong>Informe a descrição da troca: </strong></label>
          <b-form-group>
            <b-form-textarea v-model="descricaoTroca" />
          </b-form-group>
        </div>
      </b-modal>
      <b-modal
        ref="modalRecusar"
        cancel-title="Cancelar"
        cancel-variant="danger"
        title="Recusar"
        :ok-disabled="descricaoRecusado === ''"
        @hidden="clearAcaoRecusado()"
        @ok="recusar"
      >
        <div>
          <label><strong>Informe a descrição: </strong></label>
          <b-form-group>
            <b-form-textarea v-model="descricaoRecusado" />
          </b-form-group>
        </div>
      </b-modal>
      <b-modal
        ref="modalRevisarOrcamento"
        title="Revisar Orçamento"
        ok-title="Revisar"
        cancel-title="Cancelar"
        cancel-variant="danger"
        :ok-disabled="motivoRevisaoOrcamento === ''"
        @ok="revisarOrcamento"
      >
        <b-form-group>
          <b-form-textarea v-model="motivoRevisaoOrcamento" />
        </b-form-group>
      </b-modal>
      <div class="m-2">

        <!-- Table Top -->
        <b-row
          style="gap: 5px;"
        >
          <!-- Search -->
          <b-col>
            <b-form-select
              v-model="perPage"
              style="width: 70px; height: 32px; margin-top: 12px;"
              label="text"
              :options="[10, 25, 50, 100]"
              size="sm"
            />
          </b-col>
          <b-col>
            <b-form-input
              v-model="searchQuery"
              style="width: 850px; height: 32px; margin-top: 12px;"
              class="d-inline-block mr-1"
              placeholder="Pesquisar..."
              :options="[10, 15, 20]"
              type="search"
              size="sm"
              width="100%"
            />
          </b-col>
          <b-col>
            <b-button
              v-show="listFiltered.length > 0"
              style="width: 150px; height: 45px;"
              variant="outline-warning"
              @click="exportFile"
            >
              Exportar excel
            </b-button>
          </b-col>
        </b-row>

      </div>
      <b-col cols="12">
        <b-overlay
          variant="white"
          :show="loading"
          spinner-variant="primary"
          blur="0"
          opacity=".75"
          rounded="sm"
        >
          <b-table
            striped
            hover
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="listFiltered"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="searchQuery"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #cell(status)="data">
              <b-badge :variant="resolveBadgeVariantStatusOrcamento(data.item.status)">
                {{ data.item.status }}
              </b-badge>
            </template>
            <template #cell(data_retorno_orcamento)="data">
              {{ resolveDataAprovacao(data.item) }}
            </template>
            <template #cell(created_at)="data">
              {{ dataHora(data.item.created_at, true) }}
            </template>
            <template #cell(acoes)="data">
              <b-row>
                <b-col>
                  <feather-icon
                    v-b-tooltip.hover.top="'Detalhes'"
                    class="color-icon"
                    size="16"
                    icon="EyeIcon"
                    @click="openModalOrcamento(data.item)"
                  />
                </b-col>
                <b-col>
                  <feather-icon
                    v-if="data.item.status === 'AUDITADO'"
                    v-b-tooltip.hover.top="'Realizar Troca'"
                    class="color-icon"
                    size="16"
                    icon="RepeatIcon"
                    @click="openModalRealizarTroca(data.item)"
                  />
                </b-col>
                <b-col>
                  <feather-icon
                    v-if="data.item.status === 'AUDITADO'"
                    v-b-tooltip.hover.top="'RECUSAR'"
                    class="color-icon"
                    size="16"
                    icon="XIcon"
                    @click="openModalRecusar(data.item)"
                  />
                </b-col>
              </b-row>
            </template>
          </b-table>
        </b-overlay>
      </b-col>

      <b-col
        cols="12"
      >
        <b-pagination
          v-model="currentPage"
          :total-rows="items.length"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
  </b-card>

</template>

<script>
import {
  BTable, BRow, BCol, BPagination, BModal, BBadge, VBTooltip, BFormInput, BCard, BOverlay, BTabs, BTab, BButton, BFormGroup, BFormTextarea, BFormSelect,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { saveAs } from 'file-saver'
import { mapState } from 'vuex'

import { Money } from 'v-money'

import axios from '../../../../../axios-auth'

const ExcelJS = require('exceljs')

export default {
  components: {
    BTable,
    BRow,
    BOverlay,
    BCol,
    BPagination,
    BModal,
    BBadge,
    BFormInput,
    BCard,
    BTabs,
    BButton,
    BTab,
    flatPickr,
    BFormGroup,
    BFormTextarea,
    vSelect,
    BFormSelect,

    Money,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    updateGrid: {
      type: Function,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
        masked: false,
      },
      searchQuery: null,
      endpoint: process.env.VUE_APP_ROOT_API,
      nome_arquivo: null,
      perPage: 50,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'status', label: 'Status', sortable: true, class: 'text-center' },
        { key: 'created_at', label: 'Data', sortable: true, class: 'text-center' },
        { key: 'dias_vencidos',
          label: 'QDA',
          class: 'text-center',
          sortable: true,
          formatter(item) {
            if (item) {
              const dataAtual = new Date()
              const dataAbertura = new Date(item.ordem_servico.data_abertura)
              const diferencaEmDias = Math.floor((dataAtual - dataAbertura) / (1000 * 60 * 60 * 24))
              return diferencaEmDias < 0 ? 'Em andamento' : `${diferencaEmDias}`
            }
            return ''
          },
        },
        { key: 'tipo_atendimento', label: 'Tipo de Atendimento', sortable: true },
        { key: 'sinistro', label: 'Sinistro', sortable: true, class: 'text-center' },
        { key: 'seguradora', label: 'SEGURADORA', sortable: true, class: 'text-center' },
        { key: 'is', label: 'IS', sortable: true, class: 'text-center' },
        { key: 'valor_total_seguradora', label: 'Valor Seguradora', sortable: true },
        { key: 'data_retorno_orcamento', label: 'Data Aprovação', sortable: true },
        { key: 'acoes', label: 'Ações', class: 'text-center' },
      ],
      orcamento_id: null,
      infoOrcamento: {},
      backupInfoOrcamento: {},
      visualizarDadosModal: false,
      editavel: false,
      dataEnvioOrcamento: '',
      valorTotalSeguradora: '',
      dataRetornoOrcamento: '',
      statusRetorno: '',
      descricaoTroca: '',
      statusFilter: [],
      atendenteFilter: [],
      seguradoraFilter: [],
      marcaFilter: [],
      tipoAtendimentoFilter: [],
      descricaoRecusado: '',
      motivoRevisaoOrcamento: '',
    }
  },
  computed: {
    ...mapState({
      items: state => state.orcamento.items,
    }),
    tiposServicosOptions() {
      const services = [
        'MÃO DE OBRA',
        'PEÇAS',
        'OUTROS',
      ]

      return services
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    optionsStatus() {
      return [...new Set(this.items.map(item => item.status))]
    },
    optionsSeguradora() {
      const uniqueSeguradoras = new Set()
      this.items.forEach(item => {
        if (item.seguradora) {
          uniqueSeguradoras.add(item.seguradora)
        }
      })
      return [...uniqueSeguradoras]
    },
    optionsMarca() {
      const uniqueMarca = new Set()
      this.items.forEach(item => {
        if (item.marca) {
          uniqueMarca.add(item.marca)
        }
      })
      return [...uniqueMarca]
    },
    optionsAtendenteFilter() {
      return [...new Set(this.items.map(item => item.atendente))]
    },
    optionsTipoAtendimento() {
      return [...new Set(this.items.map(item => item.tipo_atendimento))]
    },
    listFiltered() {
      const { items, statusFilter: sF, tipoAtendimentoFilter: tF, atendenteFilter: aF, seguradoraFilter: seguF, marcaFilter: mF } = this
      let list = items

      if (sF.length > 0) {
        list = list.filter(item => sF.includes(item.status))
      }

      if (tF.length > 0) {
        list = list.filter(item => tF.includes(item.tipo_atendimento))
      }

      if (aF.length > 0) {
        list = list.filter(item => aF.includes(item.atendente))
      }
      if (seguF.length > 0) {
        list = list.filter(item => {
          if (item.seguradora) {
            return seguF.includes(item.seguradora)
          }
          return false
        })
      }
      if (mF.length > 0) {
        list = list.filter(item => {
          if (item.marca) {
            return mF.includes(item.marca)
          }
          return false
        })
      }

      this.$store.commit('SET_FILTERED_ITEMS', list)
      this.totalRows = list.length
      return list
    },
  },
  methods: {
    async exportFile() {
      const file = await this.getDataToExported()
      if (file) {
        saveAs(file)
      }
    },
    async getDataToExported() {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Relatório Orçamento')
      const itemsPlan = this.listFiltered

      worksheet.columns = [
        { header: 'SINISTRO', key: 'sinistro' },
        { header: 'TIPO ATENDIMENTO', key: 'tipo_atendimento' },
        { header: 'SEGURADORA', key: 'seguradora' },
        { header: 'PRODUTO', key: 'produto' },
        { header: 'LINHA', key: 'linha' },
        { header: 'REVENDA', key: 'revenda' },
        { header: 'MARCA', key: 'marca' },
        { header: 'IS', key: 'is' },
        { header: 'STATUS', key: 'status' },
        { header: 'DATA', key: 'created_at' },
        { header: 'QDA', key: 'dias_em_aberto' },
        { header: 'D. ORÇAMENTO RETORNO', key: 'data_retorno_orcamento' },
        { header: 'V. TOTAL APROVADO', key: 'valor_total_aprovado' },
        { header: 'V. TOTAL SEGURADORA', key: 'valor_total_seguradora' },
      ]

      const wk1Columns = [
        'valor_total_aprovado',
      ]

      const totais = {
        wk1: {},
      }

      itemsPlan.map(item => {
        worksheet.addRow([
          item.sinistro,
          item.tipo_atendimento,
          item.seguradora,
          item.produto,
          item.linha,
          item.revenda,
          item.marca,
          item.is ? this.valorBr(parseFloat(item.is)) : null,
          item.status,
          item.created_at ? this.dataHora(item.created_at) : null,
          item.dias_em_aberto,
          item.data_retorno_orcamento ? this.formatTimezone(item.data_retorno_orcamento) : null,
          item.valor_total_aprovado ? this.valorBr(parseFloat(item.valor_total_aprovado)) : null,
          item.valor_total_seguradora ? this.valorBr(parseFloat(item.valor_total_seguradora)) : null,
        ])
        wk1Columns.map(w => {
          if (item[w] !== null) {
            totais.wk1[w] ? totais.wk1[w] += parseFloat(item[w]) : totais.wk1[w] = parseFloat(item[w])
          }
        })
      })

      worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        row.eachCell({ includeEmpty: true }, cell => {
          if (rowNumber === 1) {
            cell.font = {
              name: 'Calibri',
              family: 2,
              bold: true,
              size: 11,
              color: { argb: 'FFFFFF' },
            }
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'fd8426' },
            }
          }
          cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
          }
          cell.alignment = {
            horizontal: 'center',
            vertical: 'center',
          }
        })
      })
      const totalRowWk1 = worksheet.addRow({
        valor_total_aprovado: totais.wk1.valor_total_aprovado,
      })
      totalRowWk1.font = {
        name: 'Calibri',
        family: 2,
        bold: true,
        size: 11,
      }
      totalRowWk1.eachCell({ includeEmpty: false }, cell => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'F4A460' },
        }
        cell.alignment = {
          horizontal: 'center',
          vertical: 'center',
        }
      })
      wk1Columns.map(key => {
        worksheet.getColumn(`${key}`).numFmt = '"R$"#,##0.00;[Red]-"R$"#,##0.00'
      })

      worksheet.columns.forEach(column => {
        let dataMax = 0
        column.eachCell({ includeEmpty: true }, cell => {
          const columnLength = cell.value ? cell.value.length : 15
          if (columnLength > dataMax) { dataMax = columnLength + 5 }
        })
        column.width = dataMax < 15 ? 15 : dataMax
      })

      let blob = null

      await workbook.xlsx.writeBuffer().then(data => {
        // eslint-disable-next-line camelcase
        blob = new File([data], 'Relatório Orçamento', { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      })
      return blob
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    openModalOrcamento(item) {
      this.infoOrcamento = item
      this.visualizarDadosModal = true
      this.$refs.showModalOrcamento.show()
    },
    toViewOs(id) {
      this.$router.push({ name: 'os-view', params: { id } })
    },
    clearAcoes() {
      this.dataEnvioOrcamento = ''
      this.dataRetornoOrcamento = ''
      this.valorTotalSeguradora = ''
      this.statusRetorno = ''
    },
    clearAcaoTroca() {
      this.orcamento_id = null
      this.descricaoTroca = ''
    },
    enviarOrcamento() {
      this.$swal({
        title: 'ATENÇÃO',
        text: 'Deseja enviar esse orçamento?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, confirmo!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const body = {
            data_envio_orcamento: this.dataEnvioOrcamento,
            orcamento_id: this.infoOrcamento.orcamento_id,
            valor_envio_seguradora: this.infoOrcamento.valor_envio_seguradora,
          }
          axios
            .post('api/orcamento/envio', body)
            .then(() => {
              this.$refs.showModalOrcamento.hide()
              this.updateGrid()
              this.clearAcoes()
              this.$swal({
                title: 'SUCESSO!',
                icon: 'success',
                text: 'ORÇAMENTO ENVIADO!',
                timer: 1500,
                showConfirmButton: false,
              })
            })
            .catch(error => {
              this.$refs.showModalOrcamento.hide()
              this.updateGrid()
              this.clearAcoes()
              if (error.response) {
                this.$swal({
                  title: 'ERRO!',
                  icon: 'error',
                  text: `Ocorreu um erro: ${error.response.data.mensagem} `,
                  customClass: {
                    confimButton: 'btn btn-danger',
                  },
                })
              }
            })
        }
      })
    },
    retornoOrcamento() {
      this.$swal({
        title: 'ATENÇÃO',
        text: 'Deseja confirmar o retorno desse orçamento?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, confirmo!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const body = {
            orcamento_id: this.infoOrcamento.orcamento_id,
            modelo: this.infoOrcamento.modelo,
            data_retorno: this.dataRetornoOrcamento,
            status_retorno: this.statusRetorno.toUpperCase(),
          }
          body.valor_retorno = this.infoOrcamento.valor_total_seguradora
          axios
            .post('api/orcamento/retorno', body)
            .then(() => {
              this.$refs.showModalOrcamento.hide()
              this.updateGrid()
              this.clearAcoes()
              this.$swal({
                title: 'SUCESSO!',
                icon: 'success',
                text: 'RETORNO CONFIRMADO!',
                timer: 1500,
                showConfirmButton: false,
              })
            })
            .catch(error => {
              this.$refs.showModalOrcamento.hide()
              this.updateGrid()
              this.clearAcoes()
              if (error.response) {
                this.$swal({
                  title: 'ERRO!',
                  icon: 'error',
                  text: `Ocorreu um erro: ${error.response.data.mensagem} `,
                  customClass: {
                    confimButton: 'btn btn-danger',
                  },
                })
              }
            })
        }
      })
    },
    resolveDataAprovacao(item) {
      if (item.status_retorno === 'APROVADO') {
        return item.data_retorno_orcamento ? this.formatTimezone(item.data_retorno_orcamento) : ''
      }
      return ''
    },
    openModalRealizarTroca(item) {
      this.orcamento_id = item.orcamento_id
      this.$swal({
        title: 'ATENÇÃO',
        text: 'Deseja confirmar a troca desse orçamento?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, confirmo!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.$refs.modalRealizarTroca.show()
          }
        })
    },
    realizarTroca() {
      const body = {
        orcamento_id: this.orcamento_id,
        descricao: this.descricaoTroca,
      }
      axios
        .post('api/orcamento/realizar/troca', body)
        .then(() => {
          this.$refs.modalRealizarTroca.hide()
          this.updateGrid()
          this.clearAcaoTroca()
          this.$swal({
            title: 'SUCESSO!',
            icon: 'success',
            text: 'TROCA CONFIRMADA!',
            timer: 1500,
            showConfirmButton: false,
          })
        })
        .catch(error => {
          this.$refs.modalRealizarTroca.hide()
          this.updateGrid()
          this.clearAcaoTroca()
          if (error.response) {
            this.$swal({
              title: 'ERRO!',
              icon: 'error',
              text: `Ocorreu um erro: ${error.response.data.mensagem} `,
              customClass: {
                confimButton: 'btn btn-danger',
              },
            })
          }
        })
    },
    openModalRecusar(item) {
      this.orcamento_id = item.orcamento_id
      this.$swal({
        title: 'ATENÇÃO',
        text: 'Deseja realizar essa ação?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, confirmo!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.$refs.modalRecusar.show()
          }
        })
    },
    recusar() {
      const body = {
        orcamento_id: this.orcamento_id,
        descricao: this.descricaoRecusado.toUpperCase(),
      }
      axios
        .post('api/orcamento/recusar', body)
        .then(() => {
          this.$refs.modalRecusar.hide()
          this.updateGrid()
          this.clearAcaoRecusado()
          this.$swal({
            title: 'SUCESSO!',
            icon: 'success',
            text: 'Ação realizado com sucesso!',
            timer: 1500,
            showConfirmButton: false,
          })
        })
        .catch(error => {
          this.$refs.modalRecusar.hide()
          this.updateGrid()
          this.clearAcaoRecusado()
          if (error.response) {
            this.$swal({
              title: 'ERRO!',
              icon: 'error',
              text: `Ocorreu um erro: ${error.response.data.mensagem} `,
              customClass: {
                confimButton: 'btn btn-danger',
              },
            })
          }
        })
    },
    clearAcaoRecusado() {
      this.orcamento_id = null
      this.descricaoRecusado = ''
    },
    openModalRevisarOrcamento() {
      this.$swal({
        title: 'ATENÇÃO',
        text: 'Deseja revisar esse orçamento?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, confirmo!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$refs.modalRevisarOrcamento.show()
        }
      })
    },
    revisarOrcamento() {
      const body = {
        orcamento_id: this.infoOrcamento.orcamento_id,
        motivo: this.motivoRevisaoOrcamento.toUpperCase(),
        tipo_revisao: 'NORMAL',
      }
      axios
        .post('api/orcamento/revisar', body)
        .then(() => {
          this.motivoRevisaoOrcamento = ''
          this.$refs.modalRevisarOrcamento.hide()
          this.updateGrid()
          this.$swal({
            title: 'SUCESSO!',
            icon: 'success',
            text: 'ORÇAMENTO ENVIADO PARA REVISÃO!',
            timer: 1500,
            showConfirmButton: false,
          })
        })
        .catch(error => {
          this.motivoRevisaoOrcamento = ''
          this.$refs.modalRevisarOrcamento.hide()
          this.updateGrid()
          if (error.response) {
            this.$swal({
              title: 'ERRO!',
              icon: 'error',
              text: `Ocorreu um erro: ${error.response.data.mensagem} `,
              customClass: {
                confimButton: 'btn btn-danger',
              },
            })
          }
        })
    },

    editOrcamento() {
      const backupItens = JSON.parse(JSON.stringify(this.infoOrcamento))

      this.backupInfoOrcamento = backupItens
      this.editavel = true
    },
    cancelEditOrcamento() {
      this.infoOrcamento = JSON.parse(JSON.stringify(this.backupInfoOrcamento))

      this.editavel = false
    },
    saveEditOrcamento() {
      this.$swal({
        title: 'ATENÇÃO',
        text: 'Salvar informações editadas?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, confirmo!',
        camcelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          const { servicos, ordem_de_servico_id: osID, orcamento_id: orcamentoID, produto_id: produtoID, modelo, numero_serie } = this.infoOrcamento
          const updatedServicos = servicos.map(service => ({
            id: service.id,
            tipo_servico: service.tipo_servico,
            descricao: service.descricao,
            observacao: service.observacao,
            quantidade: service.quantidade,
            valor_solicitado: service.valor_solicitado,
            valor_aprovado: service.valor_aprovado,
          }))
          const orcamento = {
            os_id: osID,
            orcamento_id: orcamentoID,
            data_envio_orcamento: this.dataEnvioOrcamento,
            produto: {
              produto_id: produtoID,
              modelo,
              numero_serie,
            },
            valor_total_seguradora: this.infoOrcamento.valor_total_seguradora,
          }

          // eslint-disable-next-line no-unused-vars
          const body = { orcamento, servicos: updatedServicos }

          axios.put('api/orcamento/update-orcamento-servico', { ...body }).then(() => {
            this.$refs.showModalOrcamento.hide()
            this.updateGrid()
            this.$swal({
              title: 'SUCESSO!',
              icon: 'success',
              text: 'ORÇAMENTO EDITADO!',
              timer: 1500,
              showConfirmButton: false,
            })
          }).catch(() => {
            this.$swal({
              title: 'Erro!',
              icon: 'error',
              text: 'Um error occorreu!',
              timer: 1500,
              showConfirmButton: false,
            })
          })
        }
      })
    },
    revisaoSeguradora(status) {
      const body = {
        status_revisao: status,
        orcamento_id: this.infoOrcamento.orcamento_id,
      }
      axios.post('api/orcamento/revisao/seguradora', body)
        .then(() => {
          this.$refs.modalRevisarOrcamento.hide()
          this.updateGrid()
          this.$swal({
            title: 'SUCESSO!',
            icon: 'success',
            text: `ORÇAMENTO ${status} COM SUCESSO!`,
            timer: 1500,
            showConfirmButton: false,
          })
        })
        .catch(error => {
          this.$refs.modalRevisarOrcamento.hide()
          this.updateGrid()
          if (error.response) {
            this.$swal({
              title: 'ERRO!',
              icon: 'error',
              text: `Ocorreu um erro: ${error.response.data.mensagem} `,
              customClass: {
                confimButton: 'btn btn-danger',
              },
            })
          }
        })
    },
    setStatusFilterByCard(status) {
      if (!this.statusFilter.includes(status)) this.statusFilter = [status]
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.nova-entidade {
  float: right;
  margin-right: 5px;
}

.nova-entidade:hover {
  color: blueviolet;
  cursor: pointer;
}
.per-page-selector {
  width: 90px;
}
</style>
