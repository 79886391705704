<template>
  <b-card no-body>
    <b-modal
      v-if="periodFilter"
      ref="customPeriodFilter"
      size="sm"
      ok-title="Aplicar"
      cancel-title="Cancelar"
      cancel-variant="outline-danger"
      title="Filtro personalizado"
      no-close-on-backdrop
      @cancel="clearFilterForm"
      @ok="okModalPeriodFilter()"
    >
      <b-row>
        <b-col>
          <label for="">Data Inicial</label>
          <flat-pickr
            v-model="periodFilter.startDate"
            value=""
            class="form-control"
          />
        </b-col>
        <b-col>
          <label for="">Data Final</label>
          <flat-pickr
            v-model="periodFilter.endDate"
            value=""
            class="form-control"
          />
        </b-col>
      </b-row>
    </b-modal>
    <b-card-header>
      <b-card-title>
        Olá 🎉 - {{ userName }}!!
      </b-card-title>

      <b-dropdown
        no-caret
        right
        :text="daySelected ? daySelected.label : 'Filtro por Período'"
        variant="transparent"
        class="chart-dropdown"
        toggle-class="p-50"
        size="sm"
      >
        <b-dropdown-item
          v-for="day in periodoPreset"
          :key="day.label"
          @click="setPeriodFilter(day)"
        >
          {{ day.label }}
        </b-dropdown-item>
      </b-dropdown>
    </b-card-header>

    <b-card-body>
      <b-row>
        <b-col
          class="d-flex flex-column flex-wrap text-center"
          sm="3"
        >
          <h1 class="font-large-2 font-weight-bolder mt-2 mb-0">
            {{ items.length }}
          </h1>
          <small>{{ cardText }}</small>
        </b-col>

        <b-col
          v-if="loadChart && supportTrackerRadialBar.chartOptions.labels.length !== 0"
          class="d-flex justify-content-center"
          sm="9"
        >
          <!-- <h2>teste da porra</h2> -->
          <vue-apex-charts
            type="radialBar"
            height="270"
            :options="supportTrackerRadialBar.chartOptions"
            :series="supportTrackerRadialBar.series"
          />
        </b-col>
      </b-row>

      <slot
        name="analyticFooter"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BCardBody,
  BRow,
  BCol,
  BModal,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import VueApexCharts from 'vue-apexcharts'

import { $themeColors } from '@themeConfig'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BCardBody,
    BDropdownItem,
    BRow,
    BCol,
    VueApexCharts,
    BModal,
    flatPickr,
  },
  props: {
    items: {
      required: true,
      type: Array,
    },
    // chartLabel: {
    //   required: true,
    //   type: String,
    // },
    cardText: {
      required: true,
      type: String,
    },
    notDoneStatus: {
      type: Array,
      required: true,
    },
    doneStatus: {
      type: Array,
      required: true,
    },
    isOs: {
      type: Boolean,
      default: false,
    },
    periodFilterSet: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      userName: '',
      daySelected: null,
      loadChart: false,
      supportTrackerRadialBar: {
        chartOptions: {
          plotOptions: {
            radialBar: {
              size: 150,
              offsetY: 20,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: '65%',
              },
              track: {
                background: '#fff',
                strokeWidth: '100%',
              },
              dataLabels: {
                name: {
                  offsetY: -5,
                  color: '#5e5873',
                  fontSize: '1rem',
                },
                value: {
                  offsetY: 15,
                  color: '#5e5873',
                  fontSize: '1.714rem',
                },
              },
            },
          },
          colors: [$themeColors.danger],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.primary],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            dashArray: 8,
          },
          labels: [],
        },
        series: [],
      },
      periodFilter: {
        startDate: null,
        endDate: null,
      },
    }
  },
  computed: {
    periodoPreset() {
      const fifteenDaysBeforeDate = this.getPreviousDate(25)
      const lastMonthDate = this.getPreviousDate(50)

      return [fifteenDaysBeforeDate, lastMonthDate, { label: 'Personalizado', startDate: 0, endDate: 0 }, { label: 'Todo Período' }]
    },
  },
  watch: {
    items() {
      this.loadChart = false
      setTimeout(() => {
        this.setChartData()
      })
    },
  },
  created() {
    this.userName = localStorage.getItem('userName')
  },
  methods: {
    getPreviousDate(daysBefore, current = new Date()) {
      const dateBefore = new Date(current.getTime())
      dateBefore.setDate(current.getDate() - daysBefore)

      const startDate = dateBefore.toISOString().slice(0, 10)
      const endDate = current.toISOString().slice(0, 10)

      return {
        startDate,
        endDate,
        label: `Últimos ${daysBefore} dias`,
      }
    },

    setChartData() {
      const { totalFromated, totalOsLabel } = this.dadosGraficos()

      this.supportTrackerRadialBar.chartOptions.labels = [totalOsLabel]
      this.supportTrackerRadialBar.series = [totalFromated]

      this.loadChart = true
    },

    dadosGraficos() {
      const { items } = this
      let totalNotDone = 0
      let totalDone = 0

      items.forEach(item => {
        if (this.notDoneStatus.includes(item.status)) {
          totalNotDone += 1
        }

        if (this.doneStatus.includes(item.status)) {
          totalDone += 1
        }
      })

      const totalSum = totalNotDone + totalDone

      const total = (totalDone / totalSum) * 100
      const totalFromated = Number.isFinite(total) ? total.toFixed(1) : 0

      this.loadChart = true
      return { totalFromated, totalOsLabel: `${totalDone} CONCLUÍDOS` }
    },

    setPeriodFilter(item) {
      const { startDate, endDate, label } = item

      this.periodFilter = {
        startDate: null,
        endDate: null,
        label,
      }

      if (startDate === undefined && endDate === undefined) {
        this.periodFilter = null

        this.daySelected = item
        this.periodFilterSet()

        return null
      }

      if (startDate === 0 && endDate === 0) {
        const defaultDate = this.getPreviousDate(0)

        this.periodFilter.endDate = defaultDate.endDate

        setTimeout(() => this.$refs.customPeriodFilter.show(), 500)
        return null
      }

      this.clearFilterForm()

      this.periodFilter = {
        startDate,
        endDate,
      }

      this.daySelected = item

      this.periodFilterSet({
        data_inicial: startDate,
        data_final: endDate,
      })

      return this.periodFilter
    },

    okModalPeriodFilter() {
      const { startDate, endDate, label } = this.periodFilter
      const diffStartEndDate = this.getDateDiff(new Date(startDate), new Date(endDate))

      this.daySelected = {
        startDate,
        endDate,
        label: label === 'Personalizado' ? `${this.brazilianDate(startDate)} até ${this.brazilianDate(endDate)}` : `Últimos ${diffStartEndDate} dias`,
      }

      this.periodFilterSet({
        data_inicial: startDate,
        data_final: endDate,
      })
    },
    getDateDiff(startDate, endDate) {
      // Get the Timestamp
      const startDateTimestamp = startDate.getTime()
      const endDateTimestamp = endDate.getTime()

      let calc

      // Check which timestamp is greater
      if (startDateTimestamp > endDateTimestamp) {
        calc = new Date(startDateTimestamp - endDateTimestamp)
      } else {
        calc = new Date(endDateTimestamp - startDateTimestamp)
      }

      return calc / (1000 * 3600 * 24)
    },
    brazilianDate(date) {
      const newDate = date.split('-')

      return `${newDate[2]}/${newDate[1]}/${newDate[0]}`
    },

    clearFilterForm() {
      this.periodFilter = {
        startDate: null,
        endDate: null,
      }
      this.daySelected = null
    },
  },
}
</script>
